export const CUSTOM_SCROLLBAR_STYLES = {
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const COLUMNS_DATA = [
  {id: 'stopName', label: 'Stop Name', minWidth: 100},
  {id: 'bookingStatus', label: 'Booking Status', minWidth: 100},
  {id: 'hotel', label: 'Hotel', minWidth: 100},
  {id: 'supplierBookingId', label: 'Supplier Booking Id', minWidth: 100},
  {id: 'roomName', label: 'Room Name', minWidth: 100},
  {id: 'providerAmount', label: 'Provider Amount', minWidth: 100},
  {id: 'finalAmount', label: 'Final Amount', minWidth: 100},
  {id: 'discountAmount', label: 'Discount Amount', minWidth: 100},
  {id: 'beforeDiscountAmount', label: 'Pre-Discount Amount', minWidth: 100},
  {id: 'taxAmount', label: 'Tax Amount', minWidth: 100},
  {id: 'profitAmount', label: 'Profit Amount', minWidth: 100},
  {id: 'checkIn', label: 'Checkin', minWidth: 100},
  {id: 'checkOut', label: 'Checkout', minWidth: 100},
  {id: 'cancellationDate', label: 'Cancellation Upto', minWidth: 100},
];

export const COLUMNS_HEADERS = [
  {id: 'name', key: 'name', label: 'Name'},
  {
    id: 'internalBookingId',
    key: 'internalBookingId',
    label: 'Internal Booking Id',
  },
  {id: 'bookingStatus', key: 'bookingStatus', label: 'Booking Status'},
  {id: 'productName', key: 'productName', label: 'Product'},
  {id: 'providerAmount', key: 'providerAmount', label: 'Provider Amount'},
  {id: 'finalAmount', key: 'finalAmount', label: 'Final Amount'},
  {id: 'profitAmount', key: 'profitAmount', label: 'Profit Amount'},
  {id: 'taxAmount', key: 'taxAmount', label: 'Tax Amount'},
];

export const RECORDS_PER_PAGE = 10;

export const BOOKING_STATUSES = {
  pending: {label: 'Pending', value: 'pending'},
  failed: {label: 'Failed', value: 'failed'},
  completed: {label: 'Completed', value: 'completed'},
};
