import {RECORDS_PER_PAGE} from '@/shared/constants/bookingsData';
import {getRequest} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setBookingsList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {
  hasError,
  startLoading,
  stopLoading,
  setBookingsList,
  setCurrentData,
} = actions;

// Selectors
export const getAllBookings = (state) => state.bookings.list;
export const getCurrentData = (state) => state.bookings.currentData;
export const getBookingById =
  ({bookingId}) =>
  (state) =>
    state.bookings.list.find(({id}) => id === bookingId);

// Fetch Bookings
export const fetchBookings = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: '/bookings',
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setBookingsList(body?.bookings));

  const initialData = body?.bookings.slice(0, RECORDS_PER_PAGE);
  dispatch(actions.setCurrentData(initialData));
};

export const fetchSingleBookings =
  ({id}) =>
  async (dispatch) => {
    const {error, body} = await handleAsyncRequest({
      dispatch,
      actions,
      requestFn: getRequest,
      endpoint: `/bookings/${id}`,
      toastMessage: {success: {show: false}, error: {show: true}},
    });

    if (error) throw error;
    return body?.booking;
  };
