import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '@/shared/redux/utils';
import {postRequest} from '@/shared/utils/requests';

// ----------------------------------------------------------------------
const defaultState = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'file',
  initialState: defaultState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// Upload File
export const uploadFiles =
  ({data}) =>
  async (dispatch) => {
    const {error, body} = await handleAsyncRequest({
      dispatch,
      actions,
      requestFn: postRequest,
      endpoint: '/files/upload/multiple',
      payload: data,
      toastMessage: {success: {show: true}, error: {show: true}},
    });
    if (error) throw error;
    return {fileUrls: body.files};
  };
