import {postRequest} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {clearStore, handleAsyncRequest} from '@/shared/redux/utils';

// ----------------------------------------------------------------------
const defaultState = {
  isLoading: false,
  error: null,
  currentUser: null,
};

const slice = createSlice({
  name: 'users',
  initialState: defaultState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const actions = slice.actions;

// Selectors

export const getCurrentUser = (state) => state.users.currentUser;

export const loginUser =
  ({email, password}) =>
  async (dispatch) => {
    const {body} = await handleAsyncRequest({
      dispatch,
      actions,
      requestFn: postRequest,
      endpoint: '/users/login',
      payload: {email, password},
      toastMessage: {success: {show: true}, error: {show: true}},
    });
    dispatch(actions.setCurrentUser(body.user));
  };

export const signOutUser = () => async (dispatch) => {
  const {error} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    endpoint: `/users/logout`,
    toastMessage: {success: {show: true}, error: {show: true}},
  });
  if (error) throw error;
  dispatch({type: clearStore.type});
};
